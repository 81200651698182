<template>
  <Combobox v-model="selectedOption" @update:modelValue="query = ''">
    <div class="relative">
      <ComboboxInput
        :style="{ direction: isFocused ? 'ltr' : 'rtl' }"
        class="w-full bg-slate-800 border-slate-900 text-slate-400 hover:bg-slate-700 relative inline-flex items-center rounded-r-sm px-4 py-2 text-sm font-medium pr-8"
        @change="query = $event.target.value"
        @blur="isFocused = false"
        @focus="
          isFocused = true;
          $event.target.select();
        "
        :display-value="
          (item) => {
            return item;
          }
        "
      />
      <ComboboxButton
        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
        @mousedown="query = ''"
      >
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions
        v-if="filteredFiles.length"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption
          v-for="item in filteredFiles"
          :key="item.key"
          :value="item.key"
          as="template"
          v-slot="{ active, selected }"
        >
          <li
            :class="[
              'relative cursor-pointer select-none py-2 pl-8 pr-4 block truncate',
              active ? 'bg-lime-600 text-white' : 'text-gray-900',
            ]"
          >
            <span
              style="direction: rtl"
              :class="['block truncate', selected && 'font-semibold']"
            >
              {{ item.key }}
            </span>

            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 left-0 flex items-center pl-1.5',
                active ? 'text-white' : 'text-lime-600',
              ]"
            >
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script>
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ComboboxButton,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  components: {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    ComboboxButton,
    CheckIcon,
    ChevronUpDownIcon,
  },
  props: {
    items: Object,
  },
  emits: ["selected"],
  data() {
    return {
      selectedOption: null,
      query: "",
      isFocused: false,
    };
  },
  computed: {
    filteredFiles() {
      if (this.query === "") {
        return this.itemsAsArray;
      }
      return this.itemsAsArray.filter((item) =>
        item.key.toLowerCase().includes(this.query.toLowerCase())
      );
    },
    itemsAsArray() {
      return Object.keys(this.items).map((key) => ({
        logs: this.items[key],
        key,
      }));
    },
  },
  watch: {
    items() {
      this.selectedOption = this.itemsAsArray[0].key;
    },
    selectedOption(newv) {
      const selected = this.items[newv];
      this.$emit("selected", selected);
    },
  },
  mounted() {
    if (!this.selectedOption) {
      this.selectedOption = this.itemsAsArray[0].key;
    }
  },
  methods: {},
};
</script>
