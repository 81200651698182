<template>
  <div class="flex-col flex-1 overflow-y-auto">
    <div class="sm:rounded-lg mt-2" v-if="replay && replay.system">
      <div class="sm:rounded-lg mt-2" v-if="replay && replay.system">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-base font-semibold leading-6 text-slate-300">
            System
          </h3>
          <p class="mt-1 max-w-2xl text-slate-600">
            {{ replay.system.system.manufacturer }}
            {{ replay.system.system.model }}
            {{ replay.system.system.version }}
            <span v-if="replay.system.system.virtual">(Virtual)</span>
          </p>
        </div>
      </div>

      <div class="px-4 py-5 sm:px-6">
        <div class="flex gap-x-3">
          <font-awesome-icon
            class="w-5 h-5 mt-0.5 text-gray-400"
            icon="fa-solid fa-hard-drive"
          />
          <h3 class="text-base font-semibold leading-6 text-slate-300">
            Operating System
          </h3>
        </div>
        <p class="mt-1 max-w-2xl text-slate-600">
          An operating system (OS) is system software that manages computer
          hardware and software resources, and provides common services for
          computer programs.
        </p>
      </div>
      <div class="border-t border-slate-800 px-4 py-5 sm:p-0 font-mono">
        <dl class="sm:divide-y sm:divide-slate-800">
          <div
            class="py-4 grid grid-cols-2 sm:gap-4 py-2 sm:px-6"
            v-for="(value, key) in replay.system.osInfo"
            :key="key"
          >
            <dt class="font-medium text-slate-600">{{ key }}</dt>
            <dd class="mt-1 text-slate-300 sm:mt-0" v-if="value">
              {{ value }}
            </dd>
          </div>
        </dl>
      </div>

      <div class="sm:rounded-lg mt-2" v-if="replay && replay.system">
        <div class="px-4 py-5 sm:px-6">
          <div class="flex gap-x-3">
            <font-awesome-icon
              class="w-5 h-5 mt-0.5 text-gray-400"
              icon="fa-solid fa-memory"
            />
            <h3 class="text-base font-semibold leading-6 text-slate-300">
              Memory
            </h3>
          </div>
          <p class="mt-1 max-w-2xl text-slate-600">
            In computing, memory is a device or system that is used to store
            information for immediate use.
          </p>
        </div>
        <div class="border-t border-slate-800 px-4 py-5 sm:p-0 font-mono">
          <dl class="sm:divide-y sm:divide-slate-800">
            <div
              class="py-4 grid grid-cols-2 sm:gap-4 py-2 sm:px-6"
              v-for="(value, key) in replay.system.mem"
              :key="key"
            >
              <dt class="font-medium text-slate-600">{{ key }}</dt>
              <dd class="mt-1 text-slate-300 sm:mt-0" v-if="value">
                {{ byteSize(value).value }}
                {{ byteSize(value).unit }}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div class="sm:rounded-lg mt-2" v-if="replay && replay.system">
        <div class="px-4 py-5 sm:px-6">
          <div class="flex gap-x-3">
            <font-awesome-icon
              class="w-5 h-5 mt-0.5 text-gray-400"
              icon="fa-solid fa-microchip"
            />
            <h3 class="text-base font-semibold leading-6 text-slate-300">
              CPU
            </h3>
          </div>
          <p class="mt-1 max-w-2xl text-slate-600">
            The CPU performs basic arithmetic, logic, controlling, and
            input/output (I/O) operations specified by the instructions in the
            program.
          </p>
        </div>
        <div class="border-t border-slate-800 px-4 py-5 sm:p-0 font-mono">
          <dl class="sm:divide-y sm:divide-slate-800">
            <div
              class="py-4 grid grid-cols-2 sm:gap-4 py-2 sm:px-6"
              v-for="(value, key) in replay.system.cpu"
              :key="key"
            >
              <dt class="font-medium text-slate-600">{{ key }}</dt>
              <dd class="mt-1 text-slate-300 sm:mt-0" v-if="value">
                {{ value }}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div class="sm:rounded-lg mt-2" v-if="replay && replay.system">
        <div class="px-4 py-5 sm:px-6">
          <div class="flex gap-x-3">
            <font-awesome-icon
              class="w-5 h-5 mt-0.5 text-gray-400"
              icon="fa-solid fa-display"
            />
            <h3 class="text-base font-semibold leading-6 text-slate-300">
              Graphics
            </h3>
          </div>
          <p class="mt-1 max-w-2xl text-slate-600">
            Specialized electronic circuit designed to manipulate and alter
            memory to accelerate the creation of images in a frame buffer
            intended for output to a display device.
          </p>
        </div>
        <div class="border-t border-slate-800 px-4 py-5 sm:p-0 font-mono">
          <dl class="sm:divide-y sm:divide-slate-800">
            <div
              class="py-4 py-2 sm:px-6"
              v-for="(value, key) in replay.system.graphics"
              :key="key"
            >
              <dt class="font-medium text-slate-600">{{ key }}</dt>
              <dd
                class="mt-1 text-slate-300 sm:mt-0"
                v-if="value && typeof value == 'number'"
              >
                {{ byteSize(value).value }}
                {{ byteSize(value).unit }}
              </dd>
              <div class="py-5" v-if="value && typeof value == 'object'">
                <dd class="mt-1 text-slate-300 sm:col-span-2 sm:mt-0">
                  <ul
                    role="list"
                    class="divide-y divide-slate-800 rounded-md border border-slate-800 mb-10"
                    v-for="i in value"
                    :key="i"
                  >
                    <li
                      class="flex items-center justify-between py-3 pl-3 pr-4"
                      v-for="(subValue, subKey) in i"
                      :key="subKey"
                    >
                      <div class="flex w-0 flex-1 items-center text-slate-600">
                        <span class="ml-2 w-0 flex-1 truncate">{{
                          subKey
                        }}</span>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <a
                          href="#"
                          class="font-medium text-slate-300 hover:text-lime-500"
                          >{{ subValue }}</a
                        >
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import byteSize from "byte-size";

export default {
  props: ["replay"],
  methods: {
    byteSize(...args) {
      return byteSize(...args);
    },
  },
};
</script>
