<template>
  <div class="flex flex-1 flex-col h-full">
    <div class="w-full mt-32" v-if="isLoadingLogs">
      <Spinner />
    </div>
    <div v-else class="flex flex-col flex-1">
      <div v-if="!applicationLogs.length" class="p-20 h-full">
        <div class="text-center">
          <font-awesome-icon
            icon="fa-solid fa-terminal"
            class="-ml-0.5 mr-1.5 mx-auto h-12 w-12 text-slate-400"
            aria-hidden="true"
          />

          <h3 class="mt-2 text-sm text-slate-400">Attach desktop logs here.</h3>
          <p class="mt-1 text-sm text-slate-500">
            Configure an <strong>application</strong> pattern in Dashcam
            desktop.
          </p>
          <div class="mt-6">
            <a
              href="https://docs.dashcam.io/replayable/log-and-error-tracking/getting-started"
              class="inline-flex items-center rounded-md bg-lime-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
            >
              Learn How
            </a>
          </div>
        </div>
      </div>
      <div v-else class="p-4">
        <div class="grid grid-cols-3 gap-2">
          <div class="col-span-1 relative">
            <label
              for="name"
              class="absolute -top-2 left-2 z-10 inline-block px-1 text-xs text-white"
              >App</label
            >
            <LogSelectorApp
              class="cursor-pointer z-40"
              v-if="applicationLogs.length"
              :items="applicationLogs"
              @selected="setActiveApp"
            />
          </div>
          <div class="col-span-2 relative">
            <label
              for="name"
              class="absolute -top-2 left-2 z-40 inline-block px-1 text-xs text-white"
              >Log File</label
            >
            <LogSelectorLines
              v-if="activeApp?.lines?.length || activeApp?.lines"
              class="cursor-pointer flex-grow z-30"
              :items="groupedLines"
              @selected="setActiveLines"
            />
          </div>
        </div>
        <div class="relative mt-2 flex" v-if="!isEmbedded">
          <label
            for="name"
            class="absolute -top-2 z-20 left-2 inline-block px-1 text-xs text-white"
            v-text="activeApp?.log?.type === 'cli' ? 'Search' : 'Filter'"
          ></label>
          <input
            type="text"
            name="name"
            v-model.trim="filterText"
            id="name"
            class="block flex-1 cursor-pointer bg-slate-800 border-slate-900 text-slate-400 hover:bg-slate-700 items-center rounded-r-sm px-4 py-2 text-sm font-medium"
            placeholder=""
          />
          <button
            v-if="activeApp?.log?.type === 'cli'"
            class="bg-slate-500 text-slate-300 ml-1 disabled:bg-slate-800 disabled:text-slate-500"
            :disabled="searchResultCount === 0 || searchResultIndex === 0"
            @click="searchPrevious"
          >
            <ChevronLeftIcon class="w-5 h-5" />
          </button>
          <button
            v-if="activeApp?.log?.type === 'cli'"
            class="bg-slate-500 text-slate-300 ml-1 disabled:bg-slate-800 disabled:text-slate-500"
            :disabled="
              searchResultCount === 0 ||
              searchResultIndex === searchResultCount - 1
            "
            @click="searchNext"
          >
            <ChevronRightIcon class="w-5 h-5" />
          </button>
        </div>
      </div>

      <div class="flex flex-1" style="background-color: #010917">
        <Terminal
          ref="terminal"
          v-if="activeApp?.log?.type === 'cli'"
          :items="activeLines"
          :time="videoTime"
          :filter-text="filterText"
          @updated-results="updatedSearchResults"
          class="flex-1 m-2"
        ></Terminal>
        <div v-else ref="scrollerParent" id="scrollerParent" class="flex-1">
          <DynamicScroller
            ref="scrollerChild"
            :items="lines"
            v-if="lines"
            :min-item-size="21"
            class="scroller mx-4 font-mono"
            :style="{ height: `${scrollerHeight}px` }"
            style="overflow-y: auto"
            @wheel="manualScroll"
          >
            <template v-slot="{ item, index, active }">
              <DynamicScrollerItem
                :item="item"
                :active="active"
                :size-dependencies="[item.html]"
                :data-index="index"
              >
                <div class="p-.5 flex items-begin">
                  <span
                    class="select-none text-sm font-medium text-blue-300 pr-2 hover:underline rounded-md cursor-pointer"
                    @click="setVideoTime(item.time)"
                    :class="{
                      'opacity-100': videoTime > item.time,
                      'opacity-50': videoTime < item.time,
                    }"
                  >
                    {{ timeDisplay(item.time) }}
                  </span>
                  <span
                    class="text-sm text-gray-200"
                    :class="{
                      'opacity-100': videoTime > item.time,
                      'opacity-70': videoTime < item.time,
                    }"
                  >
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span
                      v-html="
                        $sanitize(
                          item.html
                            .replaceAll('<div>', '')
                            .replaceAll('</div>', '')
                        )
                      "
                    ></span>
                  </span>
                </div>
              </DynamicScrollerItem>
            </template>
          </DynamicScroller>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { datadogLogs as log } from "@datadog/browser-logs";
import moment from "moment";
import _ from "lodash";
import AnsiToHTML from "ansi-to-html";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
import Spinner from "./Spinner.vue";
import LogSelectorApp from "./LogSelectorApp.vue";
import LogSelectorLines from "./LogSelectorLines.vue";
import Terminal from "./Terminal.vue";

const ansiToHTML = new AnsiToHTML();

export default {
  components: {
    LogSelectorApp,
    LogSelectorLines,
    Spinner,
    Terminal,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: ["applicationLogs", "isLoadingLogs", "videoTime", "isPlaying"],
  emits: ["setVideoTime", "manualScroll"],
  data() {
    return {
      activeApp: null,
      activeLines: null,
      scrollerHeight: 300,
      resizeInterval: null,
      filterText: "",
      ansiToHTML,
      searchResultIndex: 0,
      searchResultCount: 0,
    };
  },
  computed: {
    isEmbedded() {
      return this.$route.query.embed === "true";
    },
    groupedLines() {
      return _.groupBy(this.activeApp.lines, (line) => line.logFile);
    },
    lines() {
      if (this.activeApp?.log?.type === "cli") return;
      this.activeLines?.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.html = ansiToHTML
          .toHtml(item.line)
          // We replace the default white in logs with the text color we use for the line container (text-gray-200)
          // eslint-disable-next-line quotes
          .replaceAll(/style="color:#FFF"/g, 'style="color:#E5E7EB"');
        const leadingSpaces = (item.line.match(/^ +/)?.[0] ?? "").length;
        // Replace leading spaces by a filler span to keep the correct indentation
        // eslint-disable-next-line no-param-reassign
        item.html = `<span style="width: ${
          leadingSpaces * 8
        }px; display: inline-block;"></span>${item.html}`;
      });

      let returnLines = this.activeLines;

      if (this.activeLines) {
        returnLines = this.activeLines.filter((i) =>
          i?.line?.includes(this.filterText)
        );
      }

      if (!this.isPlaying) {
        return returnLines;
      }
      return returnLines.filter((item) => this.videoTime > item.time);
    },
  },
  mounted() {
    document.addEventListener("resize", this.renderScrollingLogsSize);
    this.resizeInterval = setInterval(() => {
      this.renderScrollingLogsSize();
      if (this.activeApp?.log?.type !== "cli" && this.isPlaying) {
        this.$refs.scrollerChild?.scrollToItem(this.lines.length - 1);
      }
    }, 50);
  },
  unmounted() {
    document.removeEventListener("resize", this.renderScrollingLogsSize);
    clearInterval(this.resizeInterval);
  },
  methods: {
    searchPrevious() {
      this.$refs.terminal.findPrevious();
    },
    searchNext() {
      this.$refs.terminal.findNext();
    },
    updatedSearchResults({ index, count }) {
      this.searchResultIndex = index;
      this.searchResultCount = count;
    },
    manualScroll() {
      this.$emit("manualScroll");
    },
    renderScrollingLogsSize() {
      this.scrollerHeight = this.$refs.scrollerParent?.clientHeight || 300;
    },
    setActiveApp(app) {
      this.activeApp = app;
    },
    setActiveLines(file) {
      this.activeLines = this.filterText
        ? file.filter((i) => i?.line?.includes(this.filterText))
        : file;
    },
    timeDisplay(duration) {
      let d = duration;
      if (d < 0) {
        d = 0;
      }
      const minutes = moment(d).minutes().toString().padStart(2, 0);
      const seconds = moment(d).seconds().toString().padStart(2, 0);
      return `${minutes}:${seconds}`;
    },
    setVideoTime(time) {
      this.$emit("setVideoTime", time);
    },
  },
};
</script>
